import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import BlogEntries from '../components/blog/blog-entries'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const BlogPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? '/blog/' : '/blog/' + (currentPage - 1).toString()
  const nextPage = '/blog/' + (currentPage + 1).toString()

  return (
    <Layout>
      <div class="site-content">
        <BlogEntries entries={data.allMarkdownRemark.edges} />

        <Pagination listClassName="justify-content-center">
          {!isFirst && (
            <PaginationItem>
              <Link to={prevPage} rel="prev" className="page-link">
                <i className="fas fa-hand-point-left" />
              </Link>
            </PaginationItem>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <PaginationItem
              key={`pagination-number${i + 1}`}
              active={i + 1 === currentPage}
            >
              <Link to={`/blog/${i === 0 ? '' : i + 1}`} className="page-link">
                {i + 1}
              </Link>
            </PaginationItem>
          ))}
          {!isLast && (
            <PaginationItem>
              <Link to={nextPage} rel="next" className="page-link">
                <i className="fas fa-hand-point-right" />
              </Link>
            </PaginationItem>
          )}
        </Pagination>
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "post" }, draft: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            image
            date(formatString: "D MMMM, YYYY")
            tags
            single
          }
          fields {
            slug
          }
          excerpt(pruneLength: 500)
        }
      }
    }
  }
`
